import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.block}>
                    <Link to="tel:89160110488" className={styles.tellink}>8 (916) 011 04 88</Link>
                    <Link to="mailto:romartsnos@mail.ru">romartsnos@mail.ru</Link>
                </div>
                <div className={styles.block}>
                    <Link to="policy">Пользовательское соглашение</Link>
                    <Link to="policy">Политика конфиденциальности</Link>
                </div>
            </div>
            <div className={styles.logo}>
                <Link to="/">
                    <img src="/img/LOGO.svg" alt="logo" height={300} />
                </Link>
            </div>
        </footer>
    );
}

export default Footer;