import styles from "./AboutBlock.module.css";

const AboutBlock = () => {
    return (
        <>
            <div className={styles.container}>
                <div className={styles.photoss}>
                    <div className={styles.lphotoss}>
                        <img src="/img/home/Rectangle-51.png" height={215} alt="" />
                        <img src="/img/home/Rectangle-52.png" height={215} alt="" />
                    </div>
                    <img src="/img/home/Rectangle-50.png" height={490} alt="" />
                </div>
                <div className={styles.line}></div>
                <div>
                    <h2 className={styles.subtl}>О компании</h2>
                    <div className={styles.bl}>
                        <p>Добро пожаловать в компанию "RomArtSnos" - ваш надежный партнер в сфере демонтажных работ! Мы предлагаем профессиональные услуги по разборке и демонтажу различных конструкций, зданий и сооружений.</p>
                        <p>Наша команда состоит из опытных специалистов, которые обладают необходимыми знаниями и навыками для выполнения демонтажных работ любой сложности. Мы работаем быстро, качественно и безопасно, соблюдая все необходимые стандарты и правила.</p>
                        <p>Мы осуществляем демонтаж как промышленных объектов, так и жилых зданий, гарантируя полную безопасность и эффективность процесса. Наша компания обеспечивает комплексное решение задач по демонтажу, включая вывоз и утилизацию строительного мусора.</p>
                    </div>
                </div>
            </div>

            <div className={styles.container_adapt}>
                <div className={styles.sm}>
                    <div className={styles.sline}></div>
                    <h2 className={styles.subtl_adapt}>О компании</h2>
                    <div className={styles.sline}></div>
                </div>
                <div className={styles.photoss_adapt}>
                    <div className={styles.block_adapt}>
                        <img src="/img/home/Rectangle-51.png" width='100%' alt="" />
                        <p>Наша команда состоит из опытных специалистов, которые обладают необходимыми знаниями и навыками для выполнения демонтажных работ любой сложности. Мы работаем быстро, качественно и безопасно, соблюдая все необходимые стандарты и правила.</p>
                    </div>
                    <div className={styles.pline}></div>
                    <div className={styles.block_adapt}>
                        <p>Добро пожаловать в компанию "RomArtSnos" - ваш надежный партнер в сфере демонтажных работ! Мы предлагаем профессиональные услуги по разборке и демонтажу различных конструкций, зданий и сооружений.</p>
                        <img src="/img/home/Rectangle-52.png" width='100%' alt="" />
                    </div>
                </div>
                <p>Мы осуществляем демонтаж как промышленных объектов, так и жилых зданий, гарантируя полную безопасность и эффективность процесса. Наша компания обеспечивает комплексное решение задач по демонтажу, включая вывоз и утилизацию строительного мусора.</p>
                <div>
                </div>
            </div>
        </>
    );
}

export default AboutBlock;