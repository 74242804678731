import { Link } from "react-router-dom";
import styles from "./Header.module.css";
import { useState } from "react";

const Header = () => {
    const [show, setShow] = useState(false);

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <Link to="/">
                    <img src="/img/LOGO.svg" alt="logo" height={120} />
                </Link>
                <div className={styles.sm_container}>
                    <Link to="/contacts" className={styles.bumbum}>Контакты</Link>
                    <Link to="/portfolio" className={styles.bumbum}>Портфолио</Link>
                    <Link to="/reviews" className={styles.bumbum}>Отзывы</Link>
                </div>
            </div>

            <button className={styles.btn} onClick={() => setShow(!show)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                </svg>
            </button>

            {
                show ?
                    <div className={styles.overlay}>
                        <div className={styles.sm_container_exp}>
                            <Link to="/contacts" className={styles.subt_exp}>Контакты</Link>
                            <div className={styles.wline}></div>
                            <Link to="/portfolio" className={styles.subt_exp}>Портфолио</Link>
                            <div className={styles.wline}></div>
                            <Link to="/reviews" className={styles.subt_exp}>Отзывы</Link>
                        </div>
                    </div> :
                    null
            }

            <div className={styles.sm_container}>
                <Link to="tel:89160110488" className={styles.number}>8 (916) 011-04-88</Link>
                <Link to="https://wa.me/79160110488?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82..." target="_blank"><img src="/img/whatsup_logo.svg" alt="whatsup" height={30} /></Link>
                <Link to="https://t.me/romartsnos" target="_blank"><img src="/img/telegram_logo.svg" alt="telegram" height={30} /></Link>
            </div>
        </header>
    );
}

export default Header;