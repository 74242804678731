import React from 'react';
import styles from './AdvantagesBlock.module.css';

const AdvantagesBlock = () => {
    return (
        <div className={styles.m}>
            <div className={styles.sm}>
                <div className={styles.line}></div>
                <h2>ПРЕИМУЩЕСТВА</h2>
                <div className={styles.line}></div>
            </div>
            <div className={styles.container}>
                <div className={styles.block}>
                    <img src="/img/image-9.svg" alt="scales" />
                    <h4>Цена – качество</h4>
                    <p>Мы полностью знаем свою<br /> работу и доводим ее до конца!  </p>
                </div>
                <div className={styles.block}>
                    <img src="/img/image-16.svg" alt="check mark" />
                    <h4>Демонтаж “под ключ”</h4>
                    <p>Полный цикл – от консультации,<br /> до полной расчистки участка<br /> под грабли.</p>
                </div>
                <div className={styles.block}>
                    <img src="/img/image-14.svg" alt="man" />
                    <h4>Постоянные бригады</h4>
                    <p>Имеем более 10-ти бригад,<br /> состоящий из мастеров своего<br /> дела</p>
                </div>
                <div className={styles.block}>
                    <img src="/img/image-15.svg" alt="planet" />
                    <h4>Круглогодичность</h4>
                    <p>Выполняем весь спектр услуг<br /> 365 дней в году!</p>
                </div>
            </div>
        </div>
    );
};

export default AdvantagesBlock;