import { Route, Routes } from "react-router-dom";

import HomePage from "../../pages/Home/HomePage";
import PortfolioPage from "../../pages/Portfolio/PortfolioPage";
import ContactsPage from "../../pages/Contacts/ContactsPage";
import PolicyPage from "../../pages/Policy/PolicyPage";

const AppRouter = () => {
    return (
        <Routes>
            <Route index element={<HomePage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/policy" element={<PolicyPage />} />
            <Route path="*" element={<HomePage />} />
        </Routes>
    );
}

export default AppRouter;