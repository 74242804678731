import styles from "./PortfolioPage.module.css";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ContactForm from "../../components/Contact/ContactForm";

const PortfolioPage = () => {
    return (
        <>
            <Header />
            <main className='mt-5 p-4'>
                <h1 className={styles.ttl}>Портфолио</h1>
                <div className={styles.container}>
                    <div className={styles.section1}>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0319.png" alt="" width='388px' height='232px' />
                            <img className={styles.imgg} src="/img/portfolio/IMG_0318.png" alt="" width='388px' height='232px' />
                        </div>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0317.png" alt="" width='388px' height='232px' />
                            <p>Демонтаж щитового дома  после пожара (6х8) <br />
                                Общая стоимость работ - 210.000₽ <br />
                                Время работы - 2 рабочих дня</p>
                        </div>
                    </div>
                    <div className={styles.bg1}></div>

                    <div className={styles.line}></div>

                    <div className={styles.section2}>
                        <div className={styles.s}>
                            <p>Разбор <br /> недостроенного <br />  деревянного дома  (8х8) без утилизации <br />
                                Общая стоимость работ : 100.000₽ <br />
                                Срок работ - 2 рабочих <br /> дня</p>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0314.png" alt="" width='388px' height='232px' />
                        </div>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0312.png" alt="" width='388px' height='232px' />
                            <img className={styles.imgg} src="/img/portfolio/IMG_0313.png" alt="" width='388px' height='232px' />
                        </div>
                    </div>
                    <div className={styles.bg2}></div>

                    <div className={styles.line}></div>

                    <div className={styles.section3}>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0310.png" alt="" width='388px' height='232px' />
                            <img className={styles.imgg} src="/img/portfolio/IMG_0309.png" alt="" width='388px' height='232px' />
                        </div>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0311.png" alt="" width='388px' height='232px' />
                            <p>Демонтаж бревенчатого дома (6х9) <br />
                                Общая стоимость работ - 350.000₽ <br />
                                Срок работы - 2 рабочих дня</p>
                        </div>
                    </div>
                    <div className={styles.bg3}></div>

                    <div className={styles.line}></div>

                    <div className={styles.section4}>
                        <div className={styles.s}>
                            <p>Демонтаж каркасного <br /> дома (10х10) с утилизацией <br /> фундамента <br />
                                Стоимость работ - 650.000₽ <br />
                                Время работ - 4 рабочих <br /> дня</p>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0321.png" alt="" width='388px' height='232px' />
                        </div>
                        <div className={styles.s}>
                            <img className={styles.imgg} src="/img/portfolio/IMG_0313.png" alt="" width='388px' height='232px' />
                            <img className={styles.imgg} src="/img/portfolio/IMG_0320.png" alt="" width='388px' height='232px' />
                        </div>
                    </div>
                    <div className={styles.bg4}></div>
                </div>
                <ContactForm />
            </main>
            <Footer />
        </>
    );
}

export default PortfolioPage;