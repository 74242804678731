import styles from "./CompletedBlock.module.css";
import ControlledCarousel from "../Carousel/ControlledCarousel";

const CompletedBlock = () => {
    return (
        <div className={styles.container}>
            <h2>Выполненные проекты</h2>
            <ControlledCarousel />
        </div>
    );
}

export default CompletedBlock;