import styles from "./Home.module.css";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import AdvantagesBlock from "../../components/Advantages/AdvantagesBlock";
import AboutBlock from "../../components/About/AboutBlock";
import CompletedBlock from "../../components/Completed/CompletedBlock";
import ContactForm from "../../components/Contact/ContactForm";

const HomePage = () => {
    return (
        <>
            <div className={styles.bg}>
                <div className={styles.overlay}></div>
                <Header />
                <h1 className={styles.motto}>«Ломаем стены,
                    не ломая доверие»</h1>
            </div>
            <main>
                <AdvantagesBlock />
                <AboutBlock />
                <CompletedBlock />
                <ContactForm />
            </main>
            <Footer />
        </>
    );
}

export default HomePage;