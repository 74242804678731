import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Slider from "react-slick";

const ControlledCarousel = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 1.08,
        slidesToScroll: 1,
        speed: 500,
        arrows: false
    };

    return (
        <>
            <Carousel activeIndex={index} onSelect={handleSelect} style={{ padding: '5em' }}>
                <Carousel.Item>
                    <div className='container_car'>
                        <div className='doogle_car'>
                            <div className='sq_car'>
                                <img src="/img/home/IMG-20240417-WA0014.png" alt="" height={488} width={250} />
                                <div className='smcont_car'>
                                    <img src="/img/home/IMG-20240418-WA0002.png" alt="" height={234} width={269} />
                                    <img src="/img/home/IMG-20240418-WA0038.png" alt="" height={234} width={269} />
                                </div>
                            </div>
                        </div>

                        <div className='spl_car'>
                            <div>
                                <h4 className='subtl_car'>Демонтаж</h4>
                                <p>и утилизация дома (10х10) после пожара.</p>
                            </div>

                            <div>
                                <div><span className='subtl_car'>Место: </span>Рузский район</div>
                                <div><span className='subtl_car'>Срок: </span>2 дня</div>
                                <div><span className='subtl_car'>Сумма: </span>169 000 рублей</div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='container_car'>
                        <div>
                            <div className='doogle_car'>
                                <div className='sq_car'>
                                    <img src="/img/home/20230128_110320_d545d819-c520-403f-a3b3-8aeb88ffb96b.png" alt="" height={488} width={250} />
                                    <div className='smcont_car'>
                                        <img src="/img/home/20230128_081837_85c11927-680f-4345-956a-f13ad3616e42.png" alt="" height={234} width={269} />
                                        <img src="/img/home/20230128_161555_d9d0a738-9433-4b39-b642-0c0d3c85e925.png" alt="" height={234} width={269} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='spl_car'>
                            <div>
                                <h4 className='subtl_car'>Демонтаж</h4>
                                <p>дачного <br /> каркасного дома (6х6) с <br /> последующей утилизацией.</p>
                            </div>

                            <div>
                                <div><span className='subtl_car'>Место: </span>Пушкинский район</div>
                                <div><span className='subtl_car'>Срок: </span>1 день</div>
                                <div><span className='subtl_car'>Сумма: </span>121 000 рублей</div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>

                <Carousel.Item>
                    <div className='container_car'>
                        <div>
                            <div className='doogle_car'>
                                <div className='sq_car'>
                                    <img src="/img/home/20240212_162640.png" alt="" height={488} width={250} />
                                    <div className='smcont_car'>
                                        <img src="/img/home/20231205_111926.png" alt="" height={234} width={269} />
                                        <img src="/img/home/20240213_111353.png" alt="" height={234} width={269} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='spl_car'>
                            <div>
                                <h4 className='subtl_car'>Демонтаж</h4>
                                <p>дома с <br /> цокольным этажом , гостевого <br /> дома с бассейном . Была <br /> произведена обратная засыпка <br /> котлована песком с трамбовкой.</p>
                            </div>

                            <div>
                                <div><span className='subtl_car'>Место: </span>Рублевское шоссе</div>
                                <div><span className='subtl_car'>Срок: </span>25 рабочих дней</div>
                                <div><span className='subtl_car'>Сумма: </span>~9.5 млн рублей</div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
            <div className="slider-container">
                <Slider {...settings}>
                    <div className="raketa_adapt">
                        <div className='doogle_car_adapt'>
                            <div className='sq_car'>
                                <img src="/img/home/IMG-20240417-WA0014.png" alt="" height={253} width={138} />
                                <div className='smcont_car'>
                                    <img src="/img/home/IMG-20240418-WA0002.png" alt="" height={119} width={135} />
                                    <img src="/img/home/IMG-20240418-WA0038.png" alt="" height={119} width={135} />
                                </div>
                            </div>

                            <div className='spl_car'>
                                <div>
                                    <h4 className='subtl_car'>Демонтаж</h4>
                                    <p>и утилизация дома (10х10) после пожара.</p>
                                </div>

                                <div>
                                    <div><span className='subtl_car'>Место: </span>Рузский район</div>
                                    <div><span className='subtl_car'>Срок: </span>2 дня</div>
                                    <div><span className='subtl_car'>Сумма: </span>169 000 рублей</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="raketa_adapt">
                        <div className='doogle_car_adapt'>
                            <div className='sq_car'>
                                <img src="/img/home/20230128_110320_d545d819-c520-403f-a3b3-8aeb88ffb96b.png" alt="" height={253} width={138} />
                                <div className='smcont_car'>
                                    <img src="/img/home/20230128_081837_85c11927-680f-4345-956a-f13ad3616e42.png" alt="" height={119} width={135} />
                                    <img src="/img/home/20230128_161555_d9d0a738-9433-4b39-b642-0c0d3c85e925.png" alt="" height={119} width={135} />
                                </div>
                            </div>

                            <div className='spl_car'>
                                <div>
                                    <h4 className='subtl_car'>Демонтаж</h4>
                                    <p>дачного <br /> каркасного дома (6х6) с <br /> последующей утилизацией.</p>
                                </div>

                                <div>
                                    <div><span className='subtl_car'>Место: </span>Пушкинский район</div>
                                    <div><span className='subtl_car'>Срок: </span>1 день</div>
                                    <div><span className='subtl_car'>Сумма: </span>121 000 рублей</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="raketa_adapt">
                        <div className='doogle_car_adapt'>
                            <div className='sq_car'>
                                <img src="/img/home/20240212_162640.png" alt="" height={253} width={138} />
                                <div className='smcont_car'>
                                    <img src="/img/home/20231205_111926.png" alt="" height={119} width={135} />
                                    <img src="/img/home/20240213_111353.png" alt="" height={119} width={135} />
                                </div>
                            </div>

                            <div className='spl_car'>
                                <div>
                                    <h4 className='subtl_car'>Демонтаж</h4>
                                    <p>дома с <br /> цокольным этажом , гостевого <br /> дома с бассейном . Была <br /> произведена обратная засыпка <br /> котлована песком с трамбовкой.</p>
                                </div>

                                <div>
                                    <div><span className='subtl_car'>Место: </span>Рублевское шоссе</div>
                                    <div><span className='subtl_car'>Срок: </span>25 рабочих дней</div>
                                    <div><span className='subtl_car'>Сумма: </span>~9.5 млн рублей</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default ControlledCarousel;