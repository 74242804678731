import { Link } from "react-router-dom";
import styles from "./ContactForm.module.css";
import { Clusterer, GeolocationControl, Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";

const ContactForm = () => {
    const clusterPoints = [
        [55.142353, 37.454356],
        [55.120536, 37.954765],
        [55.431141, 37.544959],
        [54.913683, 37.416600],
        [54.834578, 38.151521],
        [55.263275, 37.893115],
        [54.886279, 38.078225],
        [55.375011, 37.538985],
        [55.322988, 38.673334],
        [55.557037, 37.708758],
        [55.095271, 38.765519],
        [55.395352, 37.807281],
        [55.425631, 38.264167],
        [55.597473, 38.119790],
        [55.676736, 37.893313],
        [55.784473, 38.444846],
        [55.805846, 38.981562],
        [56.252140, 37.978782],
        [56.342897, 37.517546],
        [55.797007, 37.938113],
        [56.315328, 38.135943],
        [55.909983, 37.736710],
        [55.854476, 38.441852],
        [56.120342, 38.141565],
        [55.760509, 37.855134],
        [56.011319, 37.847151],
        [55.567570, 38.224941],
        [55.909977, 37.736678],
        [55.920217, 37.991512],
        [55.437171, 37.767991],
        [56.035867, 35.955165],
        [56.185113, 36.977622],
        [59.938678, 30.314474],
        [55.117071, 36.596984],
        [55.703645, 36.192226],
        [55.729834, 36.854451],
        [55.914659, 36.859000],
        [56.031157, 35.510377],
        [56.331592, 36.728705],
        [55.383824, 36.722815],
        [55.506963, 36.016954],
        [59.904224, 29.092223],
        [59.945651, 29.569349],
        [59.910733, 29.776422],
        [59.853353, 30.033793],
        [59.746332, 29.624712],
        [59.697356, 29.941952],
        [59.733745, 30.086195],
        [59.568404, 30.122883],
        [59.413888, 30.347192],
        [59.722324, 30.416717],
        [59.621610, 30.393456],
        [59.636173, 30.757905],
        [59.774088, 30.794555],
        [59.808048, 30.573706],
        [59.875325, 30.981436],
        [59.932989, 30.655291],
        [59.944963, 31.034738],
        [60.024636, 30.645849],
        [60.109461, 30.495067],
        [60.143527, 30.217171],
        [60.093475, 29.972031],
        [60.082456, 30.264154],
        [60.204423, 30.561432],
    ];

    const handleSubmitForm = (e) => {
        // e.preventDefault();
        alert(`Заявка успешно оставлена!`);
    }

    return (
        <>
            <div className={styles.mco}>
                <div className={styles.sm}>
                    <div className={styles.line}></div>
                    <h2>Связаться с нами</h2>
                    <div className={styles.line}></div>
                </div>
                <div className={styles.ymaps_containerr}>
                    <YMaps>
                        <Map
                            defaultState={{ center: [55.75, 37.57], zoom: 8, controls: [] }}
                            width="99vw"
                            height={400}
                        >
                            <GeolocationControl options={{ float: "right" }} />
                            <ZoomControl options={{ float: "right" }} />
                            <Clusterer
                                options={{
                                    preset: "islands#invertedVioletClusterIcons",
                                    groupByCoordinates: false,
                                }}
                            >
                                {
                                    clusterPoints.map((coordinates, index) => (
                                        <Placemark key={index} geometry={coordinates} />
                                    ))
                                }
                            </Clusterer>
                        </Map>
                    </YMaps>
                </div>
                <div className={styles.cont}>
                    <p>Введите данные, мы <br /> свяжемся для уточнения <br />деталей проекта:</p>
                    <form method="post" action='https://app.salesap.ru/web_forms/order' onSubmit={e => handleSubmitForm(e)}>
                        <input type='text' name='contact[first_name]' placeholder="Имя" className={styles.inp} />
                        <input type='text' name='contact[general_phone]' placeholder="Телефон" className={styles.inp} />
                        <input style={{ display: "none" }} type='text' name='order[name]' value='Заявка с сайта' />
                        <input type='hidden' name='source_id' value='421689' />
                        <input type='hidden' name='redirect_url' value='http://romartsnos.ru/' />
                        <input type='hidden' name='token' value='6a0d252ad73b38336af00bc4c4cd5c54' />
                        <input type='hidden' name='responsible_id' value='123416' />
                        <input type="submit" className={styles.btn} value="Узнать стоимость" />
                    </form>
                    <div className={styles.checkblock}>
                        <input type="checkbox" name="" id="chb" />
                        <label htmlFor="chb">Я ознакомлен с <Link to="policy">политикой конфидециальности</Link></label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactForm;